@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .card {
    @apply bg-white rounded-2xl p-4 shadow-md border-gray-200 border;
  }
  .btn-primary {
    @apply focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-transparent font-medium rounded-full text-sm px-5 py-2.5;
  }
}

html {
  scroll-behavior: smooth;
}

/* Card Flip styles */
.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}
